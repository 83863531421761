import React from 'react'
import  classNames  from 'classnames';
import cl from './Grid.module.css'
import SpinnerOverlay from '../spinner/SpinnerOverlay';

interface GridTableProps extends React.HTMLAttributes<HTMLDivElement>{
	children: React.ReactNode;
	vr?: boolean;
	loading?: boolean;
} 
const GridTable: React.FC<GridTableProps> = (props) => {
	  const { children, vr, loading } = props
  return (
	 <div className={classNames(cl.table, vr && cl.vr)}>
		{children}
		{loading && <SpinnerOverlay />}
	 </div>
  )
}

export default GridTable