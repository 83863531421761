import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import cl from './Overview.module.css'
import { useSelector } from 'react-redux';
import { getActiveUsers365, getTrainingSessions365, getTrainingSessionsTime365 } from '../../redux/slices/StatisticOverviewSlice'
import CustomChart from '../../components/chart/CustomChart';


function Overview() {
	const trainingSessions = useSelector(state => state.statisticOverview.trainingSessions)
	const usersActiveStatistic = useSelector(state => state.statisticOverview.activeUsers)
	const vrSessionsTimeStatistic = useSelector(state => state.statisticOverview.vrSessionsTime)

	const comID = useSelector((state) => state.userInfo.info?.company)

	const [loadingTrainings, setLoadingTrainings] = useState(true)

	const dispatch = useDispatch()

	const getNewStatistic = async () => {
		await dispatch(getActiveUsers365(comID))
		await dispatch(getTrainingSessionsTime365(comID))
		await dispatch(getTrainingSessions365(comID))
	}


	useEffect(() => {
		if (!comID) return
		setLoadingTrainings(true)
		getNewStatistic()
	}, [comID])

	const options2 = [
		{ value: 7, label: 'Last 7 days' },
		{ value: 30, label: 'Last 30 days' },
		{ value: 90, label: 'Last 90 days' },
		{ value: 365, label: 'Last 365 days' },
	]

	const [complitedTrainingsFilter, setComplitedTrainingsFilter] = useState(options2[0].value)

	const onChangeComplitedTrainingsFilter = (value) => {
		setComplitedTrainingsFilter(value.value)
	}

	const [selectFilterActiveUsers, setSelectFilterrActiveUsers] = useState(options2[0].value)

	const onChangeSelectFilterActiveUsers = (value) => {
		setSelectFilterrActiveUsers(value.value)
	}

	const [selectFilterVrSessionsTime, setSelectFilterVrSessionsTime] = useState(options2[0].value)

	const onChangeSelectFilterVrSessionsTime = (value) => {
		setSelectFilterVrSessionsTime(value.value)
	}



	return (
		<div className={cl.rows}>
			<>
				<div className={cl.column__top}>
					<CustomChart
						data={trainingSessions}
						title={trainingSessions.title}
						options={options2}
						onChangeSelectFilter={onChangeComplitedTrainingsFilter}
						selectFilter={complitedTrainingsFilter}
						loading={trainingSessions.loading}
						unit='sessions'
					/>
				</div>
				<div className={cl.column__top}>
					<CustomChart
						data={usersActiveStatistic}
						title={usersActiveStatistic.title}
						options={options2}
						onChangeSelectFilter={onChangeSelectFilterActiveUsers}
						selectFilter={selectFilterActiveUsers}
						loading={usersActiveStatistic.loading}
						unit='users'
					/>
				</div>
				<div className={cl.column__bottom}>
					<CustomChart
						data={vrSessionsTimeStatistic}
						title={vrSessionsTimeStatistic.title}
						options={options2}
						onChangeSelectFilter={onChangeSelectFilterVrSessionsTime}
						selectFilter={selectFilterVrSessionsTime}
						loading={vrSessionsTimeStatistic.loading}
						unit='min'
					/>
				</div>
			</>


		</div>
	)
}

export default Overview