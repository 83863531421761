import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TABLET_WIDTH, useWindowWidth } from '../../hooks/useWindowWidth';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTrainings } from '../../redux/slices/vrSlice';
import { toast } from 'react-toastify';
import { deleteAvatar, fetchAnotherUserInfo, fetchUserInfo, uploadAvatar } from '../../redux/slices/UserInfoSlice';

import UserAvatar from '../../components/account/UserAvatar';
import AccountTabs from '../../components/account/AccountTabs';

import BackButton from '../../UI/backButton/BackButton';
import RoleChips from '../../UI/roleChips/RoleChips';
import { Paragraph } from '../../UI/Typography/Typography';
import HeaderPage from '../../UI/headerPage/HeaderPage';
import SpinnerBlock from '../../UI/spinner/SpinnerBlock';

import cl from '../../styles/pages/settings/Account.module.css'
import TransparentOverlay from '../../UI/transparentOverlay/TransparentOverlay';
import VerificationBunner from '../../components/account/VerificationBunner';
import Button from '../../UI/Button/Button';
import { useNavigate } from 'react-router-dom';

function Account() {
	const location = useLocation();
	const accountId = useParams().accountId
	const dispatch = useDispatch();
	const winWidth = useWindowWidth()
	const navigate = useNavigate()
	const path = window.location.pathname;

	const [userInfo, setUserInfo] = useState(null)
	const info = useSelector((state) => state.userInfo.info)
	const anotherInfo = useSelector((state) => state.userInfo.anotherUser)
	const verification = useSelector((state) => state.company?.info?.confirmed_email)
	const iRole = useSelector((state) => state.userInfo.info.role)
	const { email, avatar, name, role, workerid, id } = info;
	const [avatarLink, setAvatarLink] = useState(null)

	const { trainingId } = useParams();

// Отримуємо тренінги з Redux
const trainings = useSelector(state => state.vr?.trainings);
const userID = useSelector(state => state.userInfo?.info?.id); // Отримуємо userID з Redux
const comID = useSelector(state => state.company?.info?.id); 

useEffect(() => {
    if (!trainings || trainings.length === 0) {
        dispatch(getTrainings({ comID, userID }));
    }
}, [dispatch, trainings]);
// Знаходимо потрібний тренінг
const trainingInfo = trainings?.find(t => t.training === trainingId);

// Визначаємо заголовок тренінгу
let trainingTitle = trainingInfo ? trainingInfo.name : " ";

// Якщо це тренінг "101", то підставляємо захардкоджену назву
if (trainingId === "101") {
    trainingTitle = "Feeder Protection Relay Training for Operators";
}
	useEffect(() => {
		setAvatarLink(userInfo?.avatar != null ? process.env.REACT_APP_MAIN_API_URL + `/avatar/${userInfo.avatar}?${Math.random()}` : null)
	}, [userInfo])

	useEffect(() => {
		if (accountId !== id) setUserInfo(anotherInfo)
	}, [anotherInfo])

	useEffect(() => {
		if (accountId === id) setUserInfo(info)
	}, [info])

	const teamIdFromPath = decodeURIComponent(location.pathname.split('/')[4] || "Team");
	

	const onUploadAvatar = async (file) => {
		return await dispatch(uploadAvatar(file))
	}

	const onDeleteAvatar = async () => {
		return await dispatch(deleteAvatar())
	}

	const onGetUserInfo = async (id) => {
		const res = await dispatch(fetchAnotherUserInfo(id))
		if (res.payload.status === 'success') {
			setUserInfo(res.payload.data)
		} else {
			setUserInfo(null)
			toast.error('Something went wrong')
		}
	}

	const getInfoById = async (needId) => {
		if (!id) return
		const token = localStorage.getItem('token')
		if (needId === id) {
			const res = await dispatch(fetchUserInfo({ token, id }))
			if (res.payload.status === 'success') {
				setUserInfo(res.payload.data)
			} else {
				toast.error('Data update error. Try again later')
			}
		} else {
			onGetUserInfo(needId)
		}
	}

	const access = () => {
		switch (iRole) {
			case 'OWNER':
				if (userInfo.role === 'OWNER' && info.id !== userInfo.id) {
					return false
				}
				return true
			case 'ADMIN':
				if ((userInfo.role === 'OWNER' || userInfo.role === 'ADMIN') && info.id !== userInfo.id) {
					return false
				}
				return true
			case 'ENHANCED':
				if (userInfo.role === 'ENHANCED' && info.id === userInfo.id) {
					return true
				}
				return false
			default:
				return false
		}
	}

	useEffect(() => {
		if (!id) return
		if (!accountId) return
		//;
		//if(id === accountId) {
		//	setUserInfo(info)
		//} else {
		//	setUserInfo(null)
		//	onGetUserInfo(accountId)
		//}
		getInfoById(accountId)
	}, [accountId, id])

	const title = path.includes('/home/settings/') ? <HeaderPage>Account</HeaderPage> :
		path.includes('/home/settings/user') ? <BackButton title='Account' /> : path.includes('/home/team/users/') ? <BackButton title='Users' /> : path.includes('/home/team/teams/') ? <BackButton title={teamIdFromPath}  /> : path.includes('/home/insights/') ? <BackButton title= {trainingTitle} /> : (path.includes('/home/vr/') && path.includes('/training-sessions-') && path.includes('/user-')) ? <BackButton title= {trainingTitle}/> : <BackButton title='Back' />

	const titleMob = path.includes('/home/settings/team/users/user-') ? <BackButton title='Account' /> :
		path.includes('home/settings/team/teams/') ? <BackButton title='Account' /> : path.includes('/home/settings/') ? <BackButton title='Settings' /> : <BackButton title='Back' />

	return (
		<div className="page__container">
			{/*{winWidth < TABLET_WIDTH
				? <BackButton title='Account' />
				: <HeaderPage>Account</HeaderPage>}*/}

			{winWidth < TABLET_WIDTH
				? titleMob
				: title}


			{userInfo
				? <> <div className={cl.container}>
					{userInfo.email ? <><div className={cl.wrap}>
						<div className={cl.avatar}>
							<UserAvatar deletePhoto={onDeleteAvatar} upload={onUploadAvatar} src={avatarLink} />
							{(userInfo.id !== info.id || !verification) ? <TransparentOverlay /> : null}
						</div>
						<div className={cl.info}>
							<RoleChips role={userInfo.role} />
							{userInfo.name && <Paragraph className={cl.name}>{userInfo.name}</Paragraph>}
							<Paragraph className={cl.email}>{userInfo.email}</Paragraph>
							{/*{userInfo.workerid && <Paragraph className={cl.eid}>Employee ID: {userInfo.workerid}</Paragraph>}*/}
							<Button linkbtn icon='insights' left onClick={() => navigate(`sessions`, { replace: true })}>View insights</Button>
						</div>

					</div>
						{verification ? null : <div className={cl.bunner}>
							<VerificationBunner />
						</div>}
					</>
						: <SpinnerBlock height={129} />}
				</div>
					<AccountTabs userInfo={userInfo} getInfo={getInfoById} access={access()} />
				</>
				: <SpinnerBlock height={129} />
			}

		</div>
	)
}

export default Account