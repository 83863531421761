import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

import ContentModal from '../../UI/modal/ContentModal.tsx';

import Modal from '../../UI/modal/Modal';
import Avatar from './../../UI/avatar/Avatar';

function UserAvatar({ src, deletePhoto, upload, type = 'avatar' }) {
	const [loading, setLoading] = useState(false);
	const [img, setImg] = useState(null)
	const [deleteAvatarModal, setDeleteAvatarModal] = useState(false)

	const onUpload = async (file) => {
		setLoading(true);
		const res = await upload(file);
		if (res.payload === 404) {
			toast.error('User or company not found');
		} else if (res.payload === 400) {
			toast.error('Something went wrong');
		} else {
			if (type === 'logo') {
				toast.success('Logo upload successfully');
			} else {
				toast.success('Avatar upload successfully');
			}
		}
		setLoading(false);
	}

	const onDelete = async () => {
		setLoading(true);
		const res = await deletePhoto()
		if (res.payload === 404) {
			toast.error('User or company not found');
		} else if (res.payload === 400) {
			toast.error('Something went wrong');
		} else if (res.payload === 403) {
			toast.error('User not found');
		} else {
			if (type === 'logo') {
				toast.success('Logo was deleted');
			} else {
				toast.success('Avatar was deleted');
			}
		}
		setLoading(false);
	}

	return (
		<>
			<Avatar loading={loading} onDelete={() => setDeleteAvatarModal(true)} onUpload={onUpload} src={src} type={type} />
			<Modal modalIsOpen={deleteAvatarModal} setIsOpen={setDeleteAvatarModal}>
				<ContentModal style={{ width: 328 }} setModalIsOpen={setDeleteAvatarModal} header='Delete photo?' textButton='Delete' onConfirm={onDelete} />
			</Modal>
		</>
	)
}

export default UserAvatar