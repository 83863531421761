import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { TABLET_WIDTH, useWindowWidth } from './../../hooks/useWindowWidth';

import { getAvgViewingTimeVideo, getComplitionViewingVideoRate, getTotalCountVideoViewed, getVideosStatistic365, getVideosTable } from '../../redux/slices/StatisticVideosSlice'

import CustomChart from '../../components/chart/CustomChart'
import VideoStatisticTablePR from './video360/VideoStatisticTablePR'

import StatisticsCard from '../../UI/statisticsCard/StatisticsCard'

import cl from './Overview.module.css'
import cl2 from '../../styles/pages/Insights.module.css'

const cards = [
	{ name: 'time', title: 'Avg. Viewing Time', tooltip: 'Average time spent watching videos on all platforms (Web and VR).', value: '0 min', percent: '0', caption: 'the last 30 days' },
	{ name: 'rate', title: 'Completion rate', tooltip: 'The ratio of completed videos to started but not finished videos across all platforms (Web and VR)', value: '0%', percent: '0', caption: 'the last 30 days' },
	{ name: 'total', title: 'Total video viewed', tooltip: 'Total number of videos watched, whether fully or partially, on all platforms (Web and VR).', value: '0', percent: '0', caption: 'the last 30 days' },
]

function Video360() {
	const dispatch = useDispatch()
	const winWidth = useWindowWidth()
	const comID = useSelector((state) => state.userInfo.info?.company)
	const avgViewingTimeVideo = useSelector(state => state.statisticVideos.avgViewingTimeVideo)
	const totalCountVideoViewed = useSelector(state => state.statisticVideos.totalCountVideoViewed)
	const viewingVideoRate = useSelector(state => state.statisticVideos.viewingVideoRate)
	const videosTable = useSelector(state => state.statisticVideos.videosTable)

	const videoSessionsStatistic = useSelector(state => state.statisticVideos.videoSessions)

	const [infoCards, setInfoCards] = useState(cards)

	const getAllTotal = () => {
		if (!avgViewingTimeVideo) {
			dispatch(getAvgViewingTimeVideo(comID))
		}
		if (!totalCountVideoViewed) {
			dispatch(getTotalCountVideoViewed(comID))
		}
		if (!viewingVideoRate) {
			dispatch(getComplitionViewingVideoRate(comID))
		}
	}

	const getTrainings = async () => {

		const res = await dispatch(getVideosTable(comID))
	}

	const getNewStatistic = async () => {
		await dispatch(getVideosStatistic365(comID))
	}


	useEffect(() => {
		if (!comID) return
		getAllTotal()
		getTrainings()
		getNewStatistic()
	}, [comID])

	useEffect(() => {
		if (!avgViewingTimeVideo) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'time') {
				return { ...card, value: avgViewingTimeVideo.totalDuration, percent: avgViewingTimeVideo.percent, text: 'min' }
			}
			return card
		}
		))
	}, [avgViewingTimeVideo])

	useEffect(() => {
		if (!totalCountVideoViewed) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'total') {
				;
				return { ...card, value: `${parseInt(totalCountVideoViewed.count)}`, percent: totalCountVideoViewed.percent }
			}
			return card
		}
		))
	}, [totalCountVideoViewed])

	useEffect(() => {
		if (!viewingVideoRate) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'rate') {
				return { ...card, value: `${viewingVideoRate.totatlPercent}%`, percent: viewingVideoRate.percent, }
			}
			return card
		}
		))
	}, [viewingVideoRate])

	const options = [
		{ value: 7, label: 'Last 7 days' },
		{ value: 30, label: 'Last 30 days' },
		{ value: 90, label: 'Last 90 days' },
		{ value: 365, label: 'Last 365 days' },
	]
	const [selectFilter, setSelectFilter] = useState(options[0].value)

	const onChangeSelectFilter = (value) => {
		setSelectFilter(value.value)
	}

	return (
		<div className={cl.rows}>
			<div className={cl2.cards}>
				{infoCards.map((card, index) => (
					<div className={cl2.card} key={index}>
						<StatisticsCard  {...card} />
					</div>
				))}
			</div>
			<div className={cl.column__bottom}>
				<CustomChart
					data={videoSessionsStatistic}
					title={videoSessionsStatistic.title}
					options={options}
					onChangeSelectFilter={onChangeSelectFilter}
					selectFilter={selectFilter}
					loading={videoSessionsStatistic.loading}
					unit='videos'
				/>
			</div>
			{winWidth > TABLET_WIDTH &&
				<div className={cl.column__bottom}>
					{videosTable
						? <VideoStatisticTablePR data={videosTable} loading={false} />
						: null
					}
				</div>

			}
		</div>
	)
}

export default Video360