import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import cl from './Button.module.css';
import Spinner from '../spinner/Spinner';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	left?: boolean;
	right?: boolean;
	icon?: string;
	textbtn?: boolean;
	linkbtn?: boolean;
	secondary?: boolean;
	texthover?: boolean;
	loading?: boolean;
	hideIcons?: boolean; 
	smallIconMargin?: boolean; 
}

const Button: React.FC<ButtonProps> = ({
	children,
	onClick,
	left = false,
	right = false,
	icon = '',
	textbtn = false,
	linkbtn = false,
	secondary = false,
	texthover = false,
	className = '',
	loading = false,
	hideIcons = false, 
	smallIconMargin = false,// 👈 Значення за замовчуванням
	...props
}) => {
	// Визначаємо SVG-іконку на основі icon пропа
	const iconMap: Record<string, string> = {
		plus: 'plus',
		upload: 'upload',
		download: 'file-download',
		downloaded: 'offline_pin',
		start: 'start_session',
		pause: 'pause_circle',
		play: 'play_circle',
		down: 'chevron-down',
		up: 'chevron-up',
		right: 'chevron-right',
		left: 'chevron-left',
		end: 'stop_session',
		edit: 'edit',
		addCR: 'user_group',
		assign: 'user-add',
		insights: 'view-insigts',
	};

	const iconClass = icon === 'insights' ? cl.iconInsights : '';

	const iconMargin = smallIconMargin ? '4px' : '8px';
	const svg = iconMap[icon] || ''; // Якщо icon не знайдено, то пусте значення

	// Визначаємо CSS-клас для кнопки
	const styleGuide = classNames(
		cl.btn,
		secondary ? cl.secondary : textbtn ? cl.textbtn : linkbtn ? cl.linkbtn : texthover ? cl.texthover : cl.primary,
		className
	);

	// Формуємо вміст кнопки з урахуванням hideIcons
	const buttonContent = (
		<>
			{!hideIcons && left && icon && <span className={classNames(cl.icon, cl.left, iconClass)} style={{ marginRight: iconMargin}} ><i className={`icon-${svg}`}></i></span>}
			{children}
			{!hideIcons && right && icon && <span className={classNames(cl.icon, cl.right)} style={{ marginRight: iconMargin}} ><i className={`icon-${svg}`}></i></span>}
			{loading && <div className={cl.spinner}><Spinner width={30} /></div>}
		</>
	);

	return (
		<button
			onClick={onClick}
			className={styleGuide}
			disabled={loading || props.disabled}
			{...props}
		>
			{buttonContent}
		</button>
	);
};

export default Button;
