import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Input from '../../../../UI/input/Input'

function InfoInput({ label, oldValue, name, errorInfo, callback, tooltip = '', ...props }) {
	const [loading, setLoading] = useState('')
	const [value, setValue] = useState('')
	const [err, setErr] = useState('')


	const onFocusChange = async (e) => {
		setErr('')
		if (value !== oldValue) {
			setLoading(name)
			const res = await callback(name, value)
			if (res.payload === 400 || res.payload === 403 || res.payload === 402 || res.payload === 401) {
				toast.error('Something went wrong')
				setValue(oldValue)
			} else if (res.payload === 404) {
				toast.error('User or company not found')
				setValue(oldValue)
			} else {
				toast.success(`${label} was changed`)
			}
			setLoading('')
		}
	}

	const onChange = (e) => {
		setValue(e.target.value)
		if (err) {
			setErr('')
		}
	}

	useEffect(() => {
		if (errorInfo && loading === name) {
			setErr(`${label} isn't changed`)
		}
	}, [errorInfo])

	useEffect(() => {
		setValue(oldValue)
	}, [oldValue])


	return (
		<Input tooltip={tooltip} loading={loading === name} onBlur={onFocusChange} helperText={err} err={err} value={value} onChange={onChange} label={label} {...props} />
	)
}

export default InfoInput