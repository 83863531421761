import { useMemo, useState } from 'react'
import SearchInput from '../../../UI/searchInput/SearchInput'
import moment from 'moment'
import prettyMilliseconds from 'pretty-ms'
import { Column } from 'primereact/column'
import { Skeleton } from 'primereact/skeleton'
import { DataTable } from 'primereact/datatable'

import EmptyTable from '../../../UI/emptyTable/EmptyTable'
import Spinner from '../../../UI/spinner/Spinner'

import cl from './UsersStatisticTable.module.css'

function UsersStatisticTablePR({ data, loading, ...props }) {

	const [searchInput, setSearchInput] = useState('')

	const sorted = useMemo(() => {
		if (!data) return null
		let sorted = [...data]

		sorted = sorted.filter((item) => {
			if ((searchInput && !item?.name?.toUpperCase().includes(searchInput.toUpperCase()) && !item?.email?.toUpperCase().includes(searchInput.toUpperCase()))) {
				return false;
			}

			return true;
		});
		return sorted
	}, [data, searchInput])


	const onSearchChange = (e) => {
		setSearchInput(e.target.value)
	}

	const nameTemplate = (dataRow) => {
		return dataRow.name || dataRow.email
	}

	const complitionTemplate = (dataRow) => {
		return <>{dataRow.sessionCompleted && Math.round(parseFloat(dataRow.sessionCompleted))}%</>
	}

	const durationTemplate = (dataRow) => {
		return <>{(typeof (+dataRow.averageDuration) === 'number' && (+dataRow.averageDuration) > 0 && prettyMilliseconds(dataRow.averageDuration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 })) || (typeof (+dataRow.averageDuration) === 'number' && +dataRow.averageDuration > 0 && prettyMilliseconds(dataRow.averageDuration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 })) || '0'}</>
	}

	const dateTemplate = (dataRow) => {
		return <>{dataRow.lastActivity && moment(dataRow.lastActivity).format('MMM DD, YYYY')}</>
	}

	return (
		<div style={{ width: '100%' }}>
			<div className={cl.search}>
				<SearchInput value={searchInput} setValue={setSearchInput} onChange={onSearchChange} placeholder='Search by name' />
			</div>
			{sorted ? <DataTable
				value={sorted}
				dataKey="id"
				tableStyle={{ minWidth: '45rem' }}
				selectionPageOnly
				emptyMessage={<EmptyTable icon='statistics' />}
				loading={loading}
				loadingIcon={<Spinner />}
			>
				<Column field="name" header="User" sortable style={{ paddingLeft: 16 }} body={nameTemplate}></Column>

				<Column field="sessionCount" header="VR Sessions" headerTooltip="Total number of VR training sessions (completed + incomplete) by the selected user." headerTooltipOptions={{ position: 'top' }} sortable ></Column>

				<Column field="averageDuration" sortable header='Avg. session time' headerTooltipOptions={{ position: 'top' }} headerTooltip='Avg. session time' body={durationTemplate}></Column>

				<Column field="sessionCompleted" sortable header="Completion" headerTooltipOptions={{ position: 'top' }} headerTooltip='Ratio of completed to incomplete VR training sessions.' body={complitionTemplate}></Column>

				<Column field="lastActivity" sortable header="Last activities"  body={dateTemplate}></Column>

			</DataTable>
				: !data && loading ?
					<DataTable
						value={[{ name: '', id: '' }, { name: '', id: '' }, { name: '', id: '' }]}
						dataKey="id" tableStyle={{ minWidth: '45rem' }}
						selectionPageOnly
						emptyMessage={<EmptyTable icon='statistics' />}
					>
						<Column field="videoName" header="360 video" sortable body={<Skeleton />} style={{ paddingLeft: 16 }}></Column>

						<Column field="count" header="Total views" headerTooltip="Total video views." headerTooltipOptions={{ position: 'top' }} sortable body={<Skeleton />}></Column>

						<Column field="averageDuration" sortable header="Avg. view time" headerTooltipOptions={{ position: 'top' }} headerTooltip='Average viewing time' body={<Skeleton />}></Column>

						<Column field="totalPercent" sortable header="Completion" headerTooltipOptions={{ position: 'top' }} headerTooltip='Completion rate' body={<Skeleton />}></Column>
					</DataTable> : null
			}


		</div>
	)
}

export default UsersStatisticTablePR