import prettyMilliseconds from 'pretty-ms'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Skeleton } from 'primereact/skeleton'

import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'

import EmptyTable from '../../../UI/emptyTable/EmptyTable'
import Spinner from '../../../UI/spinner/Spinner'

function VideoStatisticTablePR({ data, loading, ...props }) {

	const winWidth = useWindowWidth()

	const complitionTemplate = (dataRow) => {
		return <>{dataRow.totalPercent && Math.round(parseFloat(dataRow.totalPercent))}%</>
	}

	const durationTemplate = (dataRow) => {
		return <>{(typeof (+dataRow.averageView) === 'number' && (+dataRow.averageView) > 0 && prettyMilliseconds(dataRow.averageView, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 })) || (typeof (+dataRow.averageView) === 'number' && +dataRow.averageView > 0 && prettyMilliseconds(dataRow.averageView, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 })) || '0'}</>
	}

	return (
		<div style={{ width: '100%' }}>
			{winWidth > TABLET_WIDTH ?
				<>
					{data ? <DataTable
						value={data}
						dataKey="id"
						tableStyle={{ minWidth: '45rem' }}
						selectionPageOnly
						emptyMessage={<EmptyTable icon='statistics' />}
						loading={loading}
						loadingIcon={<Spinner />}
					>
						<Column field="videoName" header="360 video" sortable style={{ paddingLeft: 16 }}></Column>

						<Column field="count" header="Total views" headerTooltip="Total video views" headerTooltipOptions={{ position: 'top' }} sortable ></Column>

						<Column field="averageView" sortable header="Avg. view time" headerTooltipOptions={{ position: 'top' }} headerTooltip='Average viewing time' body={durationTemplate}></Column>

						<Column field="totalPercent" sortable header="Completion" headerTooltipOptions={{ position: 'top' }} headerTooltip='Completion rate' body={complitionTemplate}></Column>
					</DataTable>
						: !data && loading ?
							<DataTable
								value={[{ name: '', id: '' }, { name: '', id: '' }, { name: '', id: '' }]}
								dataKey="id" tableStyle={{ minWidth: '45rem' }}
								selectionPageOnly
								emptyMessage={<EmptyTable icon='statistics' />}
							>
								<Column field="videoName" header="360 video" sortable body={<Skeleton />} style={{ paddingLeft: 16 }}></Column>

								<Column field="count" header="Total views" headerTooltip="Total video views." headerTooltipOptions={{ position: 'top' }} sortable body={<Skeleton />}></Column>

								<Column field="averageView" sortable header="Avg. view time" headerTooltipOptions={{ position: 'top' }} headerTooltip='Average viewing time' body={<Skeleton />}></Column>

								<Column field="totalPercent" sortable header="Completion" headerTooltipOptions={{ position: 'top' }} headerTooltip='Completion rate' body={<Skeleton />}></Column>
							</DataTable> : null
					}


				</>
				: null}
		</div>
	)
}

export default VideoStatisticTablePR