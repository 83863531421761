import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getTrainingSessionsTableByUser } from "../redux/slices/VRSessionsStatisticByUserSlice";

import { TABLET_WIDTH, useWindowWidth } from "../hooks/useWindowWidth";
import { Header } from '../UI/Typography/Typography'

import VrSessionsStatisticTableByUserPR from "./personalInsights/sessions/VrSessionsStatisticTableByUserPR";

import DesktopOnly from "../UI/desktopOnly/DesktopOnly";
import SomethingWentWrong from "../UI/somethingWentWrong/SomethingWentWrong";
import BackButton from "../UI/backButton/BackButton";
import SearchInput from "../UI/searchInput/SearchInput";

import cl from "../styles/pages/VRSessionsListPage.module.css";
import Account from './settings/Account';
import Insights from './Insights';


function VRSessionsListByUserPage() {
	const { accountId } = useParams();
	const dispatch = useDispatch();

	const { loading, sessions, error } = useSelector(
		(state) => state.vrSessionsStatisticByUserSlice
	);

	const comID = useSelector((state) => state.userInfo.info?.company);

	const [trainingSessions, setTrainingSessions] = useState(null);

	useEffect(() => {
		if (!sessions) return;
		if (!accountId) return;
		if (sessions) {
			const session = sessions[accountId] || null;
			setTrainingSessions(session);
		}
	}, [sessions, accountId]);

	const onGetSessions = async () => {
		if (sessions && sessions[accountId]) {
			setTrainingSessions(sessions[accountId]);
			return;
		}

		await dispatch(getTrainingSessionsTableByUser({ comID, userId: accountId }));
	};

	useEffect(() => {
		if (!accountId) return;
		if (!comID) return;
		if (trainingSessions) return;
		onGetSessions();
	}, [accountId, comID]);

	const winWidth = useWindowWidth();

	const [searchInput, setSearchInput] = useState("");

	const onSearchChange = (e) => {
		setSearchInput(e.target.value);
	};

	const title = (
		<>
			<span>
				Account / {trainingSessions?.userName}
			</span>
			
		</>
	);

	return winWidth > TABLET_WIDTH ? (
		<div className="page__container">
			<BackButton title={title} style={{ marginBottom: 16 }} />
			{!error && (
				<>
				<div className={cl.header}>
					<Header>Training insights</Header>
				</div>
				<div className={cl.search}>
					<SearchInput
						value={searchInput}
						setValue={setSearchInput}
						onChange={onSearchChange}
						placeholder="Search by training name"
					/>
				</div>
				</>
			)}

			{trainingSessions && !error && (
				<VrSessionsStatisticTableByUserPR
					searchFilter={searchInput}
					data={trainingSessions.sessions}
					loading={loading}
				/>
			)}
			{error && <SomethingWentWrong onGetSessions={onGetSessions} />}
		</div>
	) : (
		<div className="page__container">
			<BackButton title="" style={{ marginBottom: 24 }} />
			<DesktopOnly />
		</div>
	);
}

export default VRSessionsListByUserPage;
