import React from 'react';
import classNames from 'classnames'; // Якщо у вас є динамічні класи
import cl from './GridCardVR.module.css';

import posterSk from '../../assets/skeleton/poster.png';
import { Caption, Paragraph } from '../Typography/Typography';
import CloseIcon from '../closeIcon/CloseIcon';
import { TABLET_WIDTH, useWindowWidth } from '../../hooks/useWindowWidth';

interface GridCardVRProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id'> {
  poster?: string | null;
  name: string;
  id: number | string;
  removeAccess: (id: string | number, type: string) => void;
  team?: string | null;
}

const GridCardVR: React.FC<GridCardVRProps> = ({ poster, name, id, removeAccess, team }) => {
  const winWidth = useWindowWidth();

  const preview = (poster?: string | null) => 
    poster ? `${process.env.REACT_APP_MAIN_API_URL}/poster/${poster}` : posterSk;

  const onRemoveAccess = (id: string | number) => {
    removeAccess(id, 'vr');
  };

  return winWidth > TABLET_WIDTH ? (
    <div className={cl.training}>
      <div className={cl.poster}>
        <img src={preview(poster)} alt='poster' />
      </div>
      {team && <Caption className={cl.teamName}>{team}</Caption>}
      <Paragraph className={cl.videoName}>{name}</Paragraph>
      {!team && (
        <div className={cl.close}>
          <CloseIcon dark onClick={() => onRemoveAccess(id)} />
        </div>
      )}
    </div>
  ) : (
    <div className={cl.training}>
      <div className={cl.poster}>
        <img src={preview(poster)} alt='poster' />
      </div>
      <div className={cl.text}>
        {team && <Caption className={cl.teamName}>{team}</Caption>}
        <Paragraph className={cl.videoName}>{name}</Paragraph>
      </div>
      {!team && (
        <div className={cl.close}>
          <CloseIcon onClick={() => onRemoveAccess(id)} />
        </div>
      )}
    </div>
  );
};

export default GridCardVR;
