import React, { useEffect, useState } from 'react'
import cl from './Overview.module.css'
import cl2 from '../../styles/pages/Insights.module.css'
import StatisticsCard from '../../UI/statisticsCard/StatisticsCard'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { getAvgSessionsPerUser, getAvgVRCompletedTrainingSessionPerUser, getUsersTableData, getWebSessionsDuration365 } from '../../redux/slices/StatisticUsersSlice'

import { TABLET_WIDTH, useWindowWidth } from '../../hooks/useWindowWidth'

import UsersStatisticTablePR from './users/UsersStatisticTablePR'
import CustomChart from '../../components/chart/CustomChart'

const cards = [
	{ name: 'complited', title: 'Completed VR trainings per user', tooltip: 'Average number of completed VR sessions per user across all trainings.', value: '0', percent: '0', caption: 'the last 30 days' },
	//{ name:  , title: 'Completion rate', tooltip: 'The total number of registered users on the platform by the company', value: '0%', percent: '0', caption: 'the last 30 days' },
	{ name: 'total', title: 'VR training sessions per user', tooltip: 'Average number of VR training sessions per user across all trainings, over the entire period.', value: '0', percent: '0', caption: 'the last 30 days' },
]

function Users() {
	const dispatch = useDispatch()
	const winWidth = useWindowWidth()
	const comID = useSelector((state) => state.userInfo.info?.company)
	const avgSessionsPerUser = useSelector(state => state.statisticUsers.avgSessionsPerUser)
	const avgVRCompletedTrainingSessionPerUser = useSelector(state => state.statisticUsers.avgVRCompletedTrainingSessionPerUser)
	const tableUsers = useSelector(state => state.statisticUsers.usersTableData)

	const webSessionsStatistic = useSelector(state => state.statisticUsers.webSessions)

	const [loading, setLoading] = useState(false)
	const [infoCards, setInfoCards] = useState(cards)

	const getAllTotal = () => {
		if (!avgSessionsPerUser) {
			dispatch(getAvgSessionsPerUser(comID))
		}
		if (!avgVRCompletedTrainingSessionPerUser) {
			dispatch(getAvgVRCompletedTrainingSessionPerUser(comID))
		}
	}

	const getUsers = () => {
		if (!tableUsers) {
			dispatch(getUsersTableData(comID))
		}
	}

	const getStatistics = async () => {
		await dispatch(getWebSessionsDuration365(comID))
	}


	useEffect(() => {
		if (!comID) return
		getAllTotal()
		getUsers()
		getStatistics()
	}, [comID])


	useEffect(() => {
		if (!avgSessionsPerUser) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'total') {
				return { ...card, value: `${parseFloat(avgSessionsPerUser.count?.toFixed(1)) || parseFloat(avgSessionsPerUser.count?.toFixed(1))}`, percent: avgSessionsPerUser.percent, text: '' }
			}
			return card
		}
		))
	}, [avgSessionsPerUser])

	useEffect(() => {
		if (!avgVRCompletedTrainingSessionPerUser) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'complited') {
				return { ...card, value: `${parseFloat(avgVRCompletedTrainingSessionPerUser.totatlPercent?.toFixed(1)) || parseFloat(avgVRCompletedTrainingSessionPerUser.totatlPercent?.toFixed(1))}`, percent: avgVRCompletedTrainingSessionPerUser.percent, text: '' }
			}
			return card
		}
		))
	}, [avgVRCompletedTrainingSessionPerUser])

	const options = [
		{ value: 7, label: 'Last 7 days' },
		{ value: 30, label: 'Last 30 days' },
		{ value: 90, label: 'Last 90 days' },
		{ value: 365, label: 'Last 365 days' },
	]

	const [selectFilter, setSelectFilter] = useState(options[0].value)

	const onChangeSelectFilter = (value) => {
		setSelectFilter(value.value)
	}

	return (
		<div className={cl.rows}>
			<div className={cl2.cards}>
				{infoCards.map((card, index) => (
					<div className={cl2.card} key={index}>
						<StatisticsCard  {...card} />
					</div>
				))}
			</div>
			<div className={cl.column__bottom}>
				<CustomChart
					data={webSessionsStatistic}
					title={webSessionsStatistic.title}
					options={options}
					onChangeSelectFilter={onChangeSelectFilter}
					selectFilter={selectFilter}
					loading={webSessionsStatistic.loading}
					unit='min'
				/>
			</div>
			{winWidth > TABLET_WIDTH &&
				<div className={cl.column__bottom}>
					{tableUsers
						? <UsersStatisticTablePR data={tableUsers} loading={loading} />
						: null
					}
				</div>
			}
		</div>
	)
}

export default Users