import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTotalTrainings, getTotalUsers } from '../redux/slices/StatisticOverviewSlice'

import { TABLET_WIDTH, useWindowWidth } from '../hooks/useWindowWidth'
import { useActiveTab } from "../hooks/useActiveTab";

import Overview from './insights/Overview'
import VRTraining from './insights/VRTraining'
import Video360 from './insights/Video360'
import Users from './insights/Users'

import BackButton from '../UI/backButton/BackButton'
import HeaderPage from '../UI/headerPage/HeaderPage'
import StatisticsCard from '../UI/statisticsCard/StatisticsCard'
import TabsPrime from '../UI/tabs/TabsPrime.tsx'

import cl from '../styles/pages/Insights.module.css'



const cards = [
	//{ color: 'green', title: 'Licenses allocated', tooltip: 'Licenses allocated', value: 0, percent: '0', caption: 'the last 30 days', icon: 'icon-dashboard' },
	{ name: 'users', color: 'blue', title: 'Total users', tooltip: 'The total number of registered users on the platform by the company', value: 0, percent: '0', caption: 'the last 30 days', icon: 'icon-User-team' },
	{ name: 'trainings', color: 'yellow', title: 'Total VR training ', tooltip: 'Total number of available training sessions for the company', value: 0, percent: '0', caption: 'the last 30 days', icon: 'icon-vr' },
]

const links = ['Overview', 'VR training', '360 video', 'Users']
const tabs = [<Overview />, <VRTraining />, <Video360 />, <Users />]

function Insights() {
	const winWidth = useWindowWidth()
	const dispatch = useDispatch()

	const comID = useSelector((state) => state.userInfo.info?.company)
	const totalUsers = useSelector(state => state.statisticOverview.totalUsers)
	const totalTrainings = useSelector(state => state.statisticOverview.totalTrainings)

	const [infoCards, setInfoCards] = useState(cards)

	const [activeTab, setActiveTab] = useActiveTab(0, tabs.length);


	const getAllTotal = () => {
		if (!totalUsers) {
			dispatch(getTotalUsers(comID))
		}
		if (!totalTrainings) {
			dispatch(getTotalTrainings(comID))
		}
	}

	useEffect(() => {
		if (!comID) return
		getAllTotal()
	}, [comID])

	useEffect(() => {
		if(!totalUsers) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'users') {
				return { ...card, value: totalUsers.count, percent: totalUsers.percent }
			}
			return card
		}
		))
	}, [totalUsers])

	useEffect(() => {
		if(!totalTrainings) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'trainings') {
				return { ...card, value: totalTrainings.count, percent: totalTrainings.percent }
			}
			return card
		}
		))
	}, [totalTrainings])


	const commonComponents = (
		<>
			<div className={cl.cards}>
				{infoCards.map((card, index) => (
					<div className={cl.card} key={index}>
						<StatisticsCard  {...card} />
					</div>
				))}
			</div>
			<div style={{marginTop: 18}}>
				<TabsPrime links={links} tabs={tabs} activeTab={activeTab} onSetTab={setActiveTab} />
			</div>
		</>
	)
	return (
		winWidth > TABLET_WIDTH
			? <div className='page__container'>
				<HeaderPage style={{ margin: '0 0 24px 0' }}>Company Insights</HeaderPage>
				{commonComponents}
			</div>
			: <div className='page__container'>
				<BackButton title='Company Insights' style={{ marginBottom: 16 }} />
				{commonComponents}
			</div>
	)
}

export default Insights