import React, { ReactNode } from 'react';
import cl from './Grid.module.css';


interface GridItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode; 
}

const GridItem: React.FC<GridItemProps> = ({ children, ...props }) => {
  return (
    <div className={cl.item} {...props}>
      {children}
    </div>
  );
}

export default GridItem;
