import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import cl from './FilterTabs.module.css';

interface FilterTabsProps extends React.HTMLAttributes<HTMLDivElement> {
  links: string[];                
  tabs: React.ReactNode[];          
  settedTab?: number | null;        
  onSetTab?: (tab: number | null) => void;  
  disabledTab?: string[];           
}

const FilterTabs: React.FC<FilterTabsProps> = ({
  links,
  tabs,
  settedTab,
  onSetTab,
  disabledTab = [],
  ...props 
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (settedTab === undefined || settedTab === null) return;
    setActiveTab(settedTab);
  }, [settedTab]);

  const onChangeTab = (index: number) => {
    if (onSetTab) {
      onSetTab(null);
    }
    setActiveTab(index);
  };

  return (
    <div {...props}>
      <div className={cl.links}>
        {links?.map((link, index) => (
          <div
            key={link}
            className={classNames(
              cl.link,
              activeTab === index && cl.active,
              disabledTab.includes(link) && cl.disabled
            )}
            onClick={() => onChangeTab(index)}
          >
            {link}
          </div>
        ))}
      </div>
      <div>{tabs && tabs[activeTab]}</div>
    </div>
  );
};

export default FilterTabs;
