import classNames from 'classnames';
import React from 'react';

import cl from './CloseIcon.module.css';


interface CloseIconProps extends React.HTMLAttributes<HTMLElement> {
  className?: string; 
  dark?: boolean;     
}

const CloseIcon: React.FC<CloseIconProps> = ({ className, dark = false, ...data }) => {
  return (
    <i 
      className={classNames('icon-close', cl.icon, dark && cl.dark, className)} 
      {...data} 
    />
  );
};

export default CloseIcon;
