import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { getAvgDurationOfTrainingSession, getPercentCompletedToUncompletedTrainings, getTotalVRTrainingSessions, getTrainingSessions365, getTrainingSessionsTable } from '../../redux/slices/StatisticVRSlice'

import { TABLET_WIDTH, useWindowWidth } from '../../hooks/useWindowWidth'

import CustomChart from '../../components/chart/CustomChart'
import TrainingsStatisticTablePR from './VRTraining/TrainingsStatisticTablePR'

import StatisticsCard from '../../UI/statisticsCard/StatisticsCard'

import cl from './Overview.module.css'
import cl2 from '../../styles/pages/Insights.module.css'

const cards = [
	{ name: 'time', title: 'VR training time', tooltip: 'Average duration of VR training sessions, regardless of status (completed or not completed)', value: '0 min', percent: '0', caption: 'the last 30 days' },
	{ name: 'training', title: 'Completion rate', tooltip: 'Ratio of completed to incomplete VR trainings.', value: '0%', percent: '0', caption: 'the last 30 days' },
	{ name: 'total', title: 'Total VR training sessions', tooltip: 'Total number of VR training sessions (completed, incomplete, repeated) since registration.', value: '0', percent: '0', caption: 'the last 30 days' },
]


function VRTraining() {
	const dispatch = useDispatch()
	const winWidth = useWindowWidth()
	const comID = useSelector((state) => state.userInfo.info?.company)
	const avgDurationTrainingSession = useSelector(state => state.statisticVR.avgDurationTrainingSession)
	const totalTrainingSession = useSelector(state => state.statisticVR.totalTrainingSession)
	const completedToUncompletedTrainings = useSelector(state => state.statisticVR.completedToUncompletedTrainings)
	const trainingSessionsTable = useSelector(state => state.statisticVR.trainingSessionsTable)

	const trainingSessionsStatistic = useSelector(state => state.statisticVR.trainingSessions)
	const tableLoading = useSelector(state => state.statisticVR.loading)


	const [infoCards, setInfoCards] = useState(cards)

	const getAllTotal = () => {
		if (!avgDurationTrainingSession) {
			dispatch(getAvgDurationOfTrainingSession(comID))
		}
		if (!totalTrainingSession) {
			dispatch(getTotalVRTrainingSessions(comID))
		}
		if (!completedToUncompletedTrainings) {
			dispatch(getPercentCompletedToUncompletedTrainings(comID))
		}
	}

	const getTrainings = () => {
		dispatch(getTrainingSessionsTable(comID))
	}

	const getNewStatistic = async () => {
		await dispatch(getTrainingSessions365(comID))
	}


	useEffect(() => {
		if (!comID) return
		getAllTotal()
		getTrainings()
		getNewStatistic()
	}, [comID])

	useEffect(() => {
		if (!avgDurationTrainingSession) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'time') {
				return { ...card, value: avgDurationTrainingSession.totalDuration, percent: avgDurationTrainingSession.percent, text: 'min' }
			}
			return card
		}
		))
	}, [avgDurationTrainingSession])

	useEffect(() => {
		if (!totalTrainingSession) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'total') {
				;

				return { ...card, value: `${parseInt(totalTrainingSession.count)}`, percent: totalTrainingSession.percent }
			}
			return card
		}
		))
	}, [totalTrainingSession])

	useEffect(() => {
		if (!completedToUncompletedTrainings) return
		setInfoCards(prev => prev.map((card, index) => {
			if (card.name === 'training') {
				return { ...card, value: `${parseInt(completedToUncompletedTrainings.totatlPercent)}%`, percent: null }
			}
			return card
		}
		))
	}, [completedToUncompletedTrainings])


	const options2 = [
		{ value: 7, label: 'Last 7 days' },
		{ value: 30, label: 'Last 30 days' },
		{ value: 90, label: 'Last 90 days' },
		{ value: 365, label: 'Last 365 days' },
	]

	const [selectFilter, setSelectFilter] = useState(options2[0].value)

	const onChangeSelectFilter = (value) => {
		setSelectFilter(value.value)
	}

	return (
		<div className={cl.rows}>
			<div className={cl2.cards}>
				{infoCards.map((card, index) => (
					<div className={cl2.card} key={index}>
						<StatisticsCard  {...card} />
					</div>
				))}
			</div>
			<div className={cl.column__bottom}>

				<CustomChart
					data={trainingSessionsStatistic}
					title={trainingSessionsStatistic.title}
					options={options2}
					onChangeSelectFilter={onChangeSelectFilter}
					selectFilter={selectFilter}
					loading={trainingSessionsStatistic.loading}
					unit='sessions'
				/>
			</div>
			{winWidth > TABLET_WIDTH &&
				<div className={cl.column__bottom}>
					<TrainingsStatisticTablePR data={trainingSessionsTable} loading={tableLoading} />
				</div>
			}
		</div>

	)
}

export default VRTraining