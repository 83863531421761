import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import Button from '../../../../UI/Button/Button'
import Input from '../../../../UI/input/Input'
import { Caption, Paragraph } from '../../../../UI/Typography/Typography'

import cl from '../../../../styles/components/account/tabs/personalInfo/ChangePasFromInfo.module.css'
import cl2 from '../../../../styles/components/forms/PasswordForm.module.css'
import { changePass } from '../../../../redux/slices/LoginSlice'
import { validatePassword } from '../../../../utils/Validation'

function ChangePasFromInfo() {
	const dispatch = useDispatch();
	const login = useSelector((state) => state.login)
	const { loading, error } = login
	const [show, setShow] = useState(false)
	//const [current, setCurrent] = useState('')
	//const [newPas, setNewPas] = useState('')

	const onSend = async (data) => {
		const { values, setErrors, setSubmitting, resetForm } = data
		//if (current === '' || newPas === '') return
		setSubmitting(true)
		return await dispatch(changePass({ 'oldPass': values.current, 'newPass': values.newPas }))
			.then((res) => {

				if (res.payload === 403) {
					setErrors({ newPas: 'Wrong old password' })
					values.submitError = 'Wrong old password'
				} else if (res.payload === 400) {
					setErrors({ newPas: 'Something went wrong' })
					values.submitError = 'Something went wrong'
				} else if (res.payload === 404) {
					setErrors({ newPas: 'User not found' })
					values.submitError = 'User not found'
				} else if (res.payload === 500) {
					setErrors({ newPas: 'Server error' })
					values.submitError = 'Server error'
				} else if (res.payload.status === "password changed") {
					toast.success('Password changed successfully');
					resetForm()
				} else {
					setErrors({ newPas: 'Something went wrong' })
					values.submitError = 'Something went wrong'
				}
			})
			.finally(() => {


				setSubmitting(false)
			})
	}



	//const onChange = (e) => {
	//	setErr('')
	//	if (e.target.name === 'current') setCurrent(e.target.value)
	//	if (e.target.name === 'newPas') setNewPas(e.target.value)
	//}

	return (
		<div className={cl.container}>
			<div className={cl.show} onClick={() => setShow(!show)}>
				<Paragraph>Password change</Paragraph>
				<i className={classNames(show ? 'icon-chevron-up' : 'icon-chevron-down')} />
			</div>


			{show && <Formik
				initialValues={{
					current: '',
					newPas: '',
					submitError: '',
				}}
				initialErrors={{
					current: '',
					newPas: '',
					upper: true,
					len: true,
					num: true,
					symb: false,
				}}
				validate={values => {
					
					let errors = {};
					if (!values.current) {
						errors.current = 'Required';
					} else {
						errors.current = ''
					}

					if (/[A-Z]/.test(values.newPas)) {
						errors.upper = false;
					} else {
						errors.upper = true;
					}

					if (values.newPas.length >= 8) {
						errors.len = false
					} else {
						errors.len = true
					}

					if (/[0-9]/.test(values.newPas)) {
						errors.num = false
					} else {
						errors.num = true
					}

					if (/[&=+<>,_'-\s]/.test(values.newPas)) {
						errors.symb = true
					} else {
						errors.symb = false
					}

					if (values.newPas === values.current) {
						errors.newPas = 'New password must be different from the current one'
					} else {
						errors.newPas = ''
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting, resetForm, setErrors, errors }) => {
					setSubmitting(true)
					
					onSend(values)

				}}
			>
				{({ isSubmitting, setSubmitting, setErrors, errors, values, dirty, resetForm }) => (
					<Form className={cl.form}>
						<Field name="current">
							{({
								field,
								form: { touched, errors, values },
								meta,
							}) => (
								<div className={cl.row}>
									<Input
										autoComplete="off"
										label='Current password' type='password'
										{...field}
										err={(meta.touched && errors.current) || errors.submitError}
										disabled={isSubmitting}
									/>
								</div>
							)}
						</Field>
						<Field name="newPas">
							{({
								field, // { name, value, onChange, onBlur }
								form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.

								meta,
							}) => (
								<div className={cl.row}>
									<Input
										autoComplete="off"
										label='New password'
										type='password'
										{...field}
										err={(meta.touched && errors.newPas) || errors.submitError || (meta.touched && errors.symb)}
										helperText={errors.newPas || (errors.symb && "Invalid character (& = + < > , _ ' - space)") || ''}
										disabled={isSubmitting}
									/>
								</div>
							)}
						</Field>
						<div className={cl2.conditions}>
							<div className={classNames(cl2.condition, !errors.len && cl2.checked)}>
								<i className={classNames(errors.len ? 'icon-correct' : 'icon-correct_green')} />
								<Caption>Min 8 characters</Caption>
							</div>
							<div className={classNames(cl2.condition, !errors.upper && cl2.checked)}>
								<i className={classNames(errors.upper ? 'icon-correct' : 'icon-correct_green')} />
								<Caption>Uppercase</Caption>
							</div>
							<div className={classNames(cl2.condition, !errors.num && cl2.checked)}>
								<i className={classNames(errors.num ? 'icon-correct' : 'icon-correct_green')} />
								<Caption>1 number</Caption>
							</div>
						</div>
						<Button className={cl.btn} type='button' disabled={errors.pass || errors.repeatPass || errors.len || errors.upper || errors.num || errors.symb || errors.newPas || !dirty || isSubmitting} loading={isSubmitting} onClick={() => onSend({ values, setErrors, setSubmitting, resetForm })}>Save password</Button>
					</Form>
				)}
			</Formik>
			}
		</div>
	)
}

export default ChangePasFromInfo