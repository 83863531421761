import { useSelector } from 'react-redux'

import AccountSettings from './tabs/AccountSettings'
import PersonalInfo from './tabs/PersonalInfo'
import AccountContent from './tabs/AccountContent'
import { TABLET_WIDTH, useWindowWidth } from './../../hooks/useWindowWidth';
import TabsPrime from '../../UI/tabs/TabsPrime.tsx'
import { useActiveTab } from "../../hooks/useActiveTab";

function AccountTabs({ userInfo, getInfo, access }) {
	//const userInfo = useSelector((state) => state.userInfo.info)
	const iRole = useSelector((state) => state.userInfo.info.role)
	const winWidth = useWindowWidth()
	
	const tabs = winWidth > TABLET_WIDTH
		? [
			<PersonalInfo loading={!userInfo.email} userInfo={userInfo} access={access} getInfo={getInfo} />,
			<AccountSettings info={userInfo} getInfo={getInfo} />,
			<AccountContent info={userInfo} />
		]
		: [<PersonalInfo loading={!userInfo.email} userInfo={userInfo} access={access} getInfo={getInfo} />,
		<AccountSettings info={userInfo} getInfo={getInfo} />,
		<AccountContent info={userInfo} />]

		const [activeTab, setActiveTab] = useActiveTab(0, tabs.length);
	const links = winWidth > TABLET_WIDTH
		? (iRole === 'OWNER' || iRole === 'ADMIN') ? ['Info', 'Settings', 'Content'] : ['Info']
		: (iRole === 'OWNER' || iRole === 'ADMIN') ? ['Info', 'Settings', 'Content'] : ['Info']

	return (
		<div style={{marginTop: 16}}>
			<TabsPrime links={links} tabs={tabs} activeTab={activeTab} onSetTab={setActiveTab} />
		</div>
	)
}

export default AccountTabs