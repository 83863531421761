import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import SpinnerBlock from '../spinner/SpinnerBlock';
import cl from './Avatar.module.css';

interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string | null;
  size?: string;
  onUpload: (file: File) => Promise<void>;
  loading?: boolean;
  onClean?: () => void;
  onDelete?: () => void;
  type?: 'logo' | 'user';
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  size,
  onUpload,
  loading = false,
  onClean,
  onDelete,
  type,
  ...props 
}) => {

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp'];
    if (!validFormats.includes(file.type)) {
      e.target.value = '';
      toast.warn('File format is not supported. Supported formats: jpeg, png, jpg, gif, webp');
      return;
    }

    if (file.size > 512 * 512) {
      e.target.value = '';
      toast.warn('File size is too large. Max size is 512kb');
      return;
    }

    await onUpload(file);
    e.target.value = '';
  };

  const skeleton = (
    type === 'logo' ? <i className="icon-add-home" /> : <i className="icon-User" />
  );

  return (
    <div className={classNames(cl.container, props.className)} {...props}>
      <div style={size ? { width: size, height: size } : undefined} className={cl.avatar}>
        {src && !loading ? <img src={src} alt="avatar" /> : skeleton}
        <div className={cl.overlay}>
          <i className="icon-camera" />
        </div>
        <input onChange={onChange} type="file" />
        <div className={classNames(cl.loading, loading && cl.active)}>
          <SpinnerBlock />
        </div>
      </div>

      {onDelete && src &&(
        <div className={cl.delete} onClick={onDelete}>
          <i className="icon-close" />
        </div>
      )}
    </div>
  );
};

export default Avatar;
