// msalConfig.js
export const msalConfig = {
  auth: {
    clientId: "26269bd8-6a21-4900-9d6a-4b3f06e3612c", // Вставьте сюда ваш идентификатор приложения (Application (client) ID) из Azure AD App Registration
    authority: "https://login.microsoftonline.com/common", // или ваш tenant ID, если нужно конкретное
    redirectUri: "https://dev.metaenga.com/", // Укажите ваш Redirect URI (по умолчанию может быть ваш локальный хост)
  },
  cache: {
    cacheLocation: "localStorage", // ⬅⬅⬅ Токен збережеться навіть після reload
    storeAuthStateInCookie: true,  // ⬅⬅⬅ Додатково покращує стабільність в Safari
  }
};

export const loginRequest = {
  scopes: ["User.Read"] // Скоупы для чтения профиля пользователя из MS Graph
};
