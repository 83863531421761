import React from 'react';
import cl from './TransparentOverlay.module.css';

interface TransparentOverlayProps extends React.HTMLAttributes<HTMLDivElement> {}

const TransparentOverlay: React.FC<TransparentOverlayProps> = (props) => {
  return <div className={cl.overlay} {...props}></div>;
};

export default TransparentOverlay;
