import React from 'react';
import cl from './GridCardVideo.module.css';
import posterSk from '../../assets/skeleton/poster.png';
import { Caption, Paragraph } from '../Typography/Typography';
import CloseIcon from '../closeIcon/CloseIcon';
import { TABLET_WIDTH, useWindowWidth } from '../../hooks/useWindowWidth';

interface GridCardVideoProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id'> {
  preview: string | null;    
  videoName: string;        
  id: string | number;      
  removeAccess: (id: string | number, type: string, companyId: string) => void;  
  team?: string;            
  companyId: string ;
}

const GridCardVideo: React.FC<GridCardVideoProps> = ({
  preview,
  videoName,
  id,
  removeAccess,
  team,
  companyId
}) => {
  const winWidth = useWindowWidth();

  // Функція для отримання постера
  const poster = (preview: string | null) => preview ? `${process.env.REACT_APP_MAIN_API_URL}/${preview}` : posterSk;

  // Функція видалення доступу
  const onRemoveAccess = (id: string | number) => {
    removeAccess(id, 'video', companyId);
  };

  // Рендер для мобільної версії
  const cardMobile = (
    <div className={cl.training} key={id}>
      <div className={cl.poster}>
        <img src={poster(preview)} alt='poster' />
      </div>
      <div className={cl.text}>
        {team && <Caption className={cl.teamName}>{team}</Caption>}
        <Paragraph className={cl.videoName}>{videoName}</Paragraph>
      </div>
      {!team && (
        <div className={cl.close}>
          <CloseIcon onClick={() => onRemoveAccess(id)} />
        </div>
      )}
    </div>
  );

  const cardDesktop = (
    <div className={cl.training} key={id}>
      <div className={cl.poster}>
        <img src={poster(preview)} alt='poster' />
      </div>
      {team && <Caption className={cl.teamName}>{team}</Caption>}
      <Paragraph className={cl.videoName}>{videoName}</Paragraph>
      {!team && (
        <div className={cl.close}>
          <CloseIcon dark onClick={() => onRemoveAccess(id)} />
        </div>
      )}
    </div>
  );

  return winWidth > TABLET_WIDTH ? cardDesktop : cardMobile;
};

export default GridCardVideo;
