import axios from "axios";


export class DeviceService {
	static getDevices(token, comID) {
		return axios.get(process.env.REACT_APP_MAIN_STATISTIC_URL + `/get/company/devices/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getDevice(token, comID, deviceID) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getDevice/${deviceID}/${comID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static deleteDevice(token, comID, deviceID) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/deleteDevice/${comID}/${deviceID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static renameDevice(token, comID, deviceID, name) {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/updateNameDevice/${comID}/${deviceID}/${name}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static getDeviceTrainings(token, comID, deviceID) {
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/get/training/assign/${deviceID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}

	static deleteDeviceTraining(token, comID, deviceID, trainingID) {
		return axios.delete(process.env.REACT_APP_MAIN_API_URL + `/delete/training/assign/${deviceID}/${trainingID}`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
	}
}