import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useActiveTab(defaultTab = 0, totalTabs) {
    let [searchParams, setSearchParams] = useSearchParams(); 
    let initialTab = searchParams.get('tab') || defaultTab;

    initialTab = isNaN(initialTab) ? 0 : Math.min(Math.max(+initialTab, 0), totalTabs - 1);

    const [activeTab, setActiveTab] = useState(initialTab);

    useEffect(() => {
        const validTab = Math.min(Math.max(activeTab, 0), totalTabs - 1);
        searchParams.set('tab', validTab);
        setSearchParams(searchParams);
    }, [activeTab, searchParams, setSearchParams, totalTabs]);

    return [activeTab, setActiveTab];
}

