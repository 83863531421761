import { Link } from 'react-router-dom'
import prettyMilliseconds from 'pretty-ms'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Skeleton } from 'primereact/skeleton'

import { TABLET_WIDTH, useWindowWidth } from '../../../hooks/useWindowWidth'

import EmptyTable from '../../../UI/emptyTable/EmptyTable'
import Spinner from '../../../UI/spinner/Spinner'

function TrainingsStatisticTablePR({ data, loading, ...props }) {

	const winWidth = useWindowWidth()

	const complitionTemplate = (dataRow) => {
		return <>{dataRow.totalPercent && Math.round(parseFloat(dataRow.totalPercent))}%</>
	}

	const nameTemplate = (dataRow) => {
		return <Link to={dataRow.id} className='link-dark' style={{position: 'relative'}}>{dataRow.name} <i className='icon-chevron-right' style={{ fontSize: 21, color: 'var(--field-color)', position: 'absolute', right: -20 }} />
		</Link>
	}

	const durationTemplate = (dataRow) => {
		return <>{(typeof (+dataRow.avgDuration) === 'number' && (+dataRow.avgDuration) > 0 && prettyMilliseconds(dataRow.avgDuration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 })) || (typeof (+dataRow.avgDuration) === 'number' && +dataRow.avgDuration > 0 && prettyMilliseconds(dataRow.avgDuration, { unitCount: 2, secondsDecimalDigits: 0, millisecondsDecimalDigits: 0 })) || '0'}</>
	}

	return (
		<div style={{ width: '100%' }}>
			{winWidth > TABLET_WIDTH ?
				<>
					{data ? <DataTable
						value={data}
						dataKey="name"
						tableStyle={{ minWidth: '45rem' }}
						selectionPageOnly
						emptyMessage={<EmptyTable icon='statistics' />}
						loadingIcon={<Spinner />}
					>
						<Column field="name" header="VR Training" sortable sortField='name' body={nameTemplate} style={{paddingLeft: 16}}></Column>
						<Column field="sessionCount" header="Total sessions" headerTooltip="Total number of sessions for this training (completed, incomplete, repeated) since its inception." headerTooltipOptions={{ position: 'top' }} sortable ></Column>

						<Column field="avgDuration" sortable header="Session time" headerTooltipOptions={{ position: 'top' }} headerTooltip='Average session duration for users in this VR training.' body={durationTemplate}></Column>

						<Column field="totalPercent" sortable header="Completion" headerTooltipOptions={{ position: 'top' }} headerTooltip='Ratio of completed to incomplete VR training sessions.' body={complitionTemplate}></Column>
					</DataTable>
						: !data && loading ?
							<DataTable
								value={[{ name: '', id: '1' }, { name: '', id: '2' }, { name: '', id: '3' }]}
								dataKey="id" tableStyle={{ minWidth: '45rem' }}
								selectionPageOnly
								emptyMessage={<EmptyTable icon='statistics' />}
							>
								<Column field="name" header="VR Training" sortable sortField='name' body={<Skeleton />} style={{paddingLeft: 16}}></Column>
								<Column field="sessionCount" header="Total sessions" headerTooltip="Total number of sessions for this training (completed, incomplete, repeated) since its inception." headerTooltipOptions={{ position: 'top' }} sortable sortField='usersCount' body={<Skeleton />}></Column>

								<Column field="avgDuration" sortable header="Session time" headerTooltipOptions={{ position: 'top' }} headerTooltip='Average session duration for users in this VR training.' body={<Skeleton />} ></Column>

								<Column field="totalPercent" sortable header="Completion" headerTooltipOptions={{ position: 'top' }} headerTooltip='Ratio of completed to incomplete VR training sessions.' body={<Skeleton />}></Column>
							</DataTable> : null
					}


				</>
				: null}
		</div>
	)
}

export default TrainingsStatisticTablePR