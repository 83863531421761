import { Paragraph } from '../Typography/Typography'

import cl from './AccessDenialOverlay.module.css'

interface DenialTextInformationProps {}

const DenialTextInformation: React.FC<DenialTextInformationProps> = () => {
  return (
	 <div className={cl.text__info}>
		<i className='icon-circle-info'/>
		<Paragraph>Verify your account to make changes in Settings</Paragraph>
	 </div>
  )
}

export default DenialTextInformation