import React, { useEffect, useState } from 'react';
import TooltipIcon from '../tooltipIcon/TooltipIcon';
import { Caption, Header, Paragraph } from '../Typography/Typography';

import cl from './StatisticsCard.module.css';
import prettyMilliseconds from 'pretty-ms';

const colorTheme = {
	green: {
		color: '#86E34F',
		bg: '#E7F9DC',
	},
	blue: {
		color: '#0086FF',
		bg: '#D5EAFE',
	},
	yellow: {
		color: '#F5B700',
		bg: '#FEF4D1',
	},
	violet: {
		color: '#89A6FB',
		bg: '#E6ECFF',
	},
};

interface StatisticsCardProps {
	color?: 'green' | 'blue' | 'yellow' | 'violet';
	title: string;
	tooltip: string;
	value: number | string;
	percent: number | string | 'n/a' | null;
	caption?: string;
	icon?: string;
	text?: string;
}

const StatisticsCard: React.FC<StatisticsCardProps> = ({
	color = 'yellow',
	title,
	tooltip,
	value,
	percent,
	caption = 'the last 30 days',
	icon,
	text = '',
}) => {
	const selectColor = colorTheme[color];
	const [notAval, setNotAval] = useState(false);

	useEffect(() => {
		setNotAval(percent === 'n/a');
	}, [percent]);

	return (
		<div className={cl.card}>
			<div className={cl.info}>
				<div className={cl.header}>
					<Paragraph>{title}</Paragraph>
					<TooltipIcon tooltip={tooltip} />
				</div>
				{text === 'min' ? (
					<StyledText millisec={value as number} />
				) : (
					<Header className={cl.digit}>
						{value} <span>{text}</span>
					</Header>
				)}
				{notAval ? (
					<div className={cl.na}>
						<Paragraph>n/a</Paragraph>
						<TooltipIcon tooltip="No data available" />
					</div>
				) : percent !== null ? (
					<Caption>
						<span
							style={{
								color:
									typeof percent === 'number' && percent > 0
										? 'var(--success-color)'
										: 'var(--error-color)',
							}}
						>
							{typeof percent === 'number' && percent > 0
								? '+' + percent
								: percent}
							%
						</span>{' '}
						{caption}
					</Caption>
				) : null}
			</div>
			{icon && (
				<div
					className={cl.icon}
					style={{
						color: selectColor?.color,
						background: selectColor?.bg,
					}}
				>
					<i className={icon} />
				</div>
			)}
		</div>
	);
};

interface StyledTextProps {
	millisec?: number;
}

const StyledText: React.FC<StyledTextProps> = ({ millisec = 0 }) => {
	const [formattedText, setFormattedText] = useState('');

	useEffect(() => {
		let textContent;
		if (millisec) {
			textContent = prettyMilliseconds(+millisec, {
				unitCount: 2,
				verbose: true,
				secondsDecimalDigits: 0,
				millisecondsDecimalDigits: 0,
			});
		} else {
			textContent = '0 min';
		}

		const formatted = textContent.replace(/(\d+)|([^\d]+)/g, (_, digits, letters) => {
			if (digits) {
				return `<span class="insights__digit">${digits}</span>`;
			} else if (letters) {
				return `<span class="insights__letter">${letters} </span>`;
			}
			return ''; 
		});

		setFormattedText(formatted);
	}, [millisec]);

	return <p dangerouslySetInnerHTML={{ __html: formattedText }} />;
};

export default StatisticsCard;
