import { useEffect, useState } from 'react'
import {Subheader } from '../../../UI/Typography/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { getVideosByGroup, getVideosByUser } from '../../../redux/slices/VideosSlice'
import { removeContentFromUser, removeMetaengaContentFromUser } from '../../../redux/slices/UsersSlice'
import { deleteTrainingFromUser } from '../../../redux/slices/vrSlice'
import { getPersonalVRAccess, getTeamVRAccess } from '../../../redux/slices/AccessContentSlice'

import Modals from '../../../pages/team/users/Modals'
import ContentGrid from '../../../pages/settings/account/accountContent/ContentGrid'

import EmptyNotice from '../../../UI/emptyNotice/EmptyNotice.tsx'
import FilterTabs from '../../../UI/filterTabs/FilterTabs'
import AccessDenialOverlay from '../../../UI/accessDenialOverlay/AccessDenialOverlay'

import cl from './AccountContent.module.css'

function AccountContent({ info }) {
	const dispatch = useDispatch()
	const verification = useSelector((state) => state.company?.info?.confirmed_email)
	const personalVR = useSelector((state) => state.contentAccess.personalVRAccess)
	const teamVR = useSelector((state) => state.contentAccess.teamVRAccess)

	const [personalVideos, setPersonalVideos] = useState([])
	const [teamVideos, setTeamVideos] = useState([])
	const [loadingPersonal, setLoadingPersonal] = useState(true)
	const [loadingTeams, setLoadingTeams] = useState(true)

	const [vidId, setVidId] = useState('')
	const [action, setAction] = useState('')

	const onGetPersonalVideos = async () => {
		const res = await dispatch(getVideosByUser({ comID: info.company, userID: info.id }))
		;
		if (res.payload.status === 'success') {
			setPersonalVideos(res.payload.data)
		}
		setLoadingPersonal(false)
	}

	const onGetTeamVideos = async () => {
		;
		let groups = info.group
		if (!groups) {
			setLoadingTeams(false)
			return
		}
		;
		let videos = []
		let error = []
		for (let i = 0; i < groups.length; i++) {
			const res = await dispatch(getVideosByGroup({ comID: info.company, groupName: groups[i], userId: info.id }))
			;
			if (res.payload.status === 'success') {
				const comVideos = res.payload.data.map((video) => {
					return {
						...video,
						uId: groups[i] + video.id,
						team: groups[i]
					}
				})
				videos = [...videos, ...comVideos]
			} else {
				error.push(groups[i])
			}
		}
		;
		setTeamVideos(videos)
		if (error.length > 0) {
			toast.error('Something went wrong')
		}
		setLoadingTeams(false)
	}

	const onGetPersonalVR = async () => {
		const res = await dispatch(getPersonalVRAccess({ comID: info.company, userId: info.id }))
		;
	}

	const onGetTeamVR = async () => {
		;
		let groups = info.group
		if (!groups) {
			setLoadingTeams(false)
			return
		}
		let error = []
		for (let i = 0; i < groups.length; i++) {
			const res = await dispatch(getTeamVRAccess({ comID: info.company, groupName: groups[i] }))
			;
			if (res.payload.data.status !== 'success') {
				error.push(groups[i])
			}
		}
		if (error.length > 0) {
			toast.error('Something went wrong')
		}
		setLoadingTeams(false)
	}


	useEffect(() => {
		onGetPersonalVideos()
		onGetTeamVideos()
		onGetPersonalVR()
		onGetTeamVR()
	}, [])


	const onRemoveAccess = (id, type, companyId) => {
		//const admId = localStorage.getItem('id')
		//if(info.id === admId) return toast.error('You can not remove access from yourself')
		setVidId({ id, companyId })
		if (type === 'video') setAction('removeAccess')
		if (type === 'vr') setAction('removeAccessVR')
	}

	const removeAccess = async () => {
		setLoadingPersonal(true)
		;
		if (action === 'removeAccess') {
			setAction('')

			if (vidId.companyId === 'metaenga') {
				const res = await dispatch(removeMetaengaContentFromUser({ company: info.company, user: info.id, video: vidId.id }))
				if (res.payload === 'success') {
					toast.success('Access removed')
					onGetPersonalVideos()
				} else {
					toast.error('Something went wrong')
				}
			} else {
				const res = await dispatch(removeContentFromUser({ toUser: info.id, contentArr: [vidId.id] }))
				if (res.payload === 'success') {
					toast.success('Access removed')
					onGetPersonalVideos()
				} else {
					toast.error('Something went wrong')
				}
			}
		} else if (action === 'removeAccessVR') {
			setAction('')
			const res = await dispatch(deleteTrainingFromUser({ comID: info.company, userID: info.id, vrTraining: [vidId.id] }))
			if (res.payload.status === 'success') {
				toast.success('Access removed')
				onGetPersonalVR()
			} else {
				toast.error('Something went wrong')
			}
		}

		setVidId('')
		setLoadingPersonal(false)
	}

	const personalTabs = [
		<Content info={info}
			onRemoveAccess={onRemoveAccess}
			content={personalVideos}
			loading={loadingPersonal}
		/>,
		<Content info={info}
			onRemoveAccess={onRemoveAccess}
			content={personalVR}
			loading={loadingPersonal}
			vr
		/>
	]

	const teamTabs = [
		<Content info={info}
			content={teamVideos}
			loading={loadingTeams}
		/>,
		<Content info={info}
			content={teamVR}
			loading={loadingTeams}
			vr
		/>
	]

	if(!verification) return <AccessDenialOverlay />

	return (
		<div className={cl.container}>

			<div className={cl.contentBlock}>
				{(personalVideos?.length > 0 || personalVR?.length > 0) &&
					<><Subheader className={cl.subtitle}>Personal content</Subheader>
						<FilterTabs links={['360 video', 'VR trainings']} disabledTab={[personalVideos?.length === 0 && '360 video', personalVR?.length === 0 && 'VR trainings']} tabs={personalTabs} settedTab={personalVideos?.length === 0 ? 1 : null} />
					</>
				}
			</div>

			<div className={cl.contentBlock}>
				{(teamVideos?.length > 0 || teamVR?.length > 0) &&
					<><Subheader>Team content</Subheader>
						<FilterTabs links={['360 video', 'VR trainings']} tabs={teamTabs} disabledTab={[teamVideos?.length === 0 && '360 video', teamVR?.length === 0 && 'VR trainings']} settedTab={teamVideos.length === 0 ? 1 : null} />
					</>
				}
			</div>

			{teamVideos?.length === 0 && !loadingTeams && personalVideos?.length === 0 && personalVR?.length === 0 && teamVR?.length === 0 && !loadingPersonal
				&& <EmptyNotice style={{ marginTop: 35 }} text="It's empty here" subtext='No content available yet' icon='icon-video_library' />}
			{action && <Modals actions={action} onClose={() => setAction('')} onConfirm={removeAccess} />}
		</div>
	)
}

function Content(props) {
	const { onRemoveAccess = null, content, loading, vr = false } = props
	;
	return (
		<ContentGrid content={content} loading={loading} onRemoveAccess={onRemoveAccess} vr={vr} />
	)
}



export default AccountContent