import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import Modals from "../../../pages/team/users/Modals";
import {
  addToTeam,
  removeFromTeam,
} from "../../../redux/slices/TeamSettingsSlice";
import { createTeam, getTeams } from "../../../redux/slices/TeamsSlice";
import { fetchUserInfo } from "../../../redux/slices/UserInfoSlice";
import {
  activateUsers,
  changeUserRole,
  deactivateUsers,
} from "../../../redux/slices/UsersSlice";
import CloseIcon from "../../../UI/closeIcon/CloseIcon";
import Input from "../../../UI/input/Input";
import Modal from "../../../UI/modal/Modal";
import RoleChips from "../../../UI/roleChips/RoleChips";
import Spinner from "../../../UI/spinner/Spinner";
import SpinnerOverlay from "../../../UI/spinner/SpinnerOverlay";
import Switch from "../../../UI/switch/Switch";
import { Caption, Paragraph } from "../../../UI/Typography/Typography";
import AddToTeamsModal from "../../modal/AddToTeamsModal";
import ContentModal from "../../../UI/modal/ContentModal.tsx";
import teamsEmptyImg from "../../../assets/icons/empty/teams_empty.png";

import cl from "./AccountSettings.module.css";
import AccessDenialOverlay from "../../../UI/accessDenialOverlay/AccessDenialOverlay";

function AccountSettings({ info, getInfo }) {
  const icons = [
    {
      icon:
        info.status === "DEACTIVATED"
          ? "icon-user_reactivate"
          : "icon-User_deactivate",
      title: info.status === "DEACTIVATED" ? "Reactivate" : "Deactivate",
      action: "deactivate",
    },
    { icon: "icon-User-team", title: "Add to team", action: "add" },
    { icon: "icon-User-new-team", title: "Create team", action: "create" },
  ];

  const uSelfIcons = [
    { icon: "icon-User-team", title: "Add to team", action: "add" },
    { icon: "icon-User-new-team", title: "Create team", action: "create" },
  ];

  const dispatch = useDispatch();
  const verification = useSelector(
    (state) => state.company?.info?.confirmed_email
  );
  const iRole = useSelector((state) => state.userInfo.info.role);
  const iId = useSelector((state) => state.userInfo.info.id);
  const allTeams = useSelector((state) => state.teams?.teams);

  const [admin, setAdmin] = useState(true);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addToTeamsModal, setAddToTeamsModal] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [errorAdd, setErrorAdd] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [modalAction, setModalAction] = useState(null);

  useEffect(() => {
    if (info?.role === "ADMIN" && admin) {
      return;
    }
    if (info?.role === "ADMIN" && !admin) {
      setAdmin(true);
      return;
    }
    if (info?.role !== "ADMIN" && admin) {
      setAdmin(false);
      return;
    }
  }, [info.role, admin]);

  const swichActions = async (action, data) => {
    switch (action) {
      case "deactivate":
        onDeactivate();
        break;
      case "add":
        await onGetTeams();
        setAddToTeamsModal(true);
        break;
      case "create":
        setCreateModal(true);
        break;
      default:
        break;
    }
  };

  const onDeleteTeam = (team) => {
	setSelectedTeam(team);
	setModalAction("removeUserFromTeam");
  };
  

  const handleConfirmAction = async () => {
	if (modalAction === "removeUserFromTeam" && selectedTeam) {
	  setLoading(true);
	  const res = await dispatch(
		removeFromTeam({ groupName: selectedTeam, userId: info.id })
	  );
	  if (res.payload?.status === "success") {
		getInfo(info.id); // Оновлює інформацію про користувача
		toast.success("User removed from team");
	  } else {
		toast.error("Something went wrong");
	  }
	  setLoading(false);
	  setModalAction(null); // Закриваємо модалку
	  setSelectedTeam(null); // Очищаємо вибір
	}
  };
  

  const checkTeamsInStore = async () => {
    let teams;
    if (!allTeams) {
      const res = await dispatch(getTeams(info.company));
      if (res.payload?.status === "success") {
        teams = res.payload?.groupUsers;
      } else {
        teams = [];
      }
    } else {
      teams = allTeams;
    }
    return teams;
  };

  const onGetTeams = async () => {
    setLoadingAdd(true);
    const teams = await checkTeamsInStore();
    if (teams.length === 0) {
      toast.warn("No available teams");
      setAvailableTeams([]);
      setLoadingAdd(false);
      return;
    }
    const availTeams = teams
      .filter((team) => !info?.group?.includes(team.Name))
      .map((team) => ({ ...team, groupName: team.Name }));
    if (availTeams.length === 0) {
      toast.warn("No available teams");
      setAvailableTeams([]);
      setLoadingAdd(false);
      return;
    }
    setAvailableTeams(availTeams);
    setLoadingAdd(false);
  };

  const onAddToTeams = async (checkedTeams, onClean) => {
    setErrorAdd("");
    setLoadingAdd(true);
    let success = [];
    let error = [];
    for (let i = 0; i < checkedTeams.length; i++) {
      const res = await dispatch(
        addToTeam({ groupName: checkedTeams[i], userArr: [info.id] })
      );
      if (res.payload.status === "success") {
        success.push(checkedTeams[i]);
      } else {
        error.push(checkedTeams[i]);
      }
    }
    if (success.length === checkedTeams.length) {
      toast.success("User added to teams");
      setAddToTeamsModal(false);
      onClean();
      getInfo(info.id);
    }
    if (error.length !== 0) {
      setErrorAdd("Something went wrong");
    }
    setLoadingAdd(false);
  };

  const onChangeRole = async () => {
    if (iRole !== "OWNER") {
      toast.error("You are not allowed to change role");
      return;
    }
    setLoading(true);
    const res = await dispatch(
      changeUserRole({ changeId: info.id, role: admin ? "ENHANCED" : "ADMIN" })
    );
    if (res.payload === "success") {
      toast.success("Role changed");
      getInfo(info.id);
    } else {
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const onDeactivate = async () => {
    setLoading(true);
    if (info.status === "DEACTIVATED") {
      const res = await dispatch(
        activateUsers({ userId: info.id, email: info.email })
      );
      if (res.payload.data.status === "success") {
        toast.success("User activated");
        getInfo(info.id);
      } else {
        toast.error("Something went wrong");
      }
    } else {
      const res = await dispatch(
        deactivateUsers({ userId: info.id, email: info.email })
      );
      if (res.payload.data.status === "success") {
        toast.success("User deactivated");
        getInfo(info.id);
      } else {
        toast.error("Something went wrong");
      }
    }
    setLoading(false);
  };

  const [createInput, setCreateInput] = useState("");
  const [createInputError, setCreateInputError] = useState("");
  const [createModal, setCreateModal] = useState(false);

  const onChangeCreateInput = (e) => {
    setCreateInput(e.target.value);
    setCreateInputError("");
  };

  const onCreateTeam = async () => {
    if (createInput.includes("/")) {
      setCreateInputError('Team name must not contain "/"');
      return;
    }
    setLoading(true);
    if (createInput) {
      await dispatch(getTeams(info.company));
      const res = await dispatch(createTeam(createInput));
      if (res.payload.status === "success") {
        const id = localStorage.getItem("id");
        const userArr = [info.id, id];
        await onAddToCreatedTeam(res?.meta?.arg, userArr);
      } else if (res.payload === 401) {
        setCreateInputError("Team already exist");
      } else if (res.payload === 402) {
        setCreateInputError("Permission denied");
      } else {
        setCreateInputError("Something went wrong");
      }
    } else if (createInput.length < 2) {
      setCreateInputError("Team name must be at least 2 characters");
    }
    setLoading(false);
  };

  const onAddToCreatedTeam = async (groupName, userArr) => {
    const res = await dispatch(addToTeam({ groupName, userArr }));
    if (res.payload.status === "success") {
      toast.success("Team created");
      setCreateInput("");
      setCreateModal(false);
      getInfo(info.id);
    } else if (res.payload === 401) {
      toast.error("Team already exist"); //!проверить коды в доке
    } else if (res.payload === 402) {
      toast.error("Permission denied");
    } else {
      toast.error("Team created, but user not added");
    }
  };

  if (!verification) return <AccessDenialOverlay />;

  return (
    <div className={classNames("account__container", cl.container)}>
      <div className={cl.actions}>
        {iId
          ? iId === info.id ||
            info.role === "OWNER" ||
            (info.role === "ADMIN" && iRole !== "OWNER")
            ? uSelfIcons.map((action) => (
                <div
                  className={cl.action}
                  key={action.title}
                  onClick={() => swichActions(action.action)}
                >
                  <i className={action.icon} />
                  <Caption>{action.title}</Caption>
                  {action.action === "add" && loadingAdd && (
                    <SpinnerOverlay width={35} />
                  )}

                  {modalAction && (
                    <Modals
                      ids={[info.id]}
                      teamName={selectedTeam}
                      actions={modalAction}
                      onClose={() => setModalAction(null)}
                      onConfirm={handleConfirmAction}
					  userName={info.name}
                    />
                  )}
                </div>
              ))
            : info.role !== "OWNER"
            ? icons.map((action) => (
                <div
                  className={cl.action}
                  key={action.title}
                  onClick={() => swichActions(action.action)}
                >
                  <i className={action.icon} />
                  <Caption>{action.title}</Caption>
                  {action.action === "add" && loadingAdd && (
                    <SpinnerOverlay width={35} />
                  )}
                </div>
              ))
            : null
          : null}
      </div>

<Paragraph>Teams ({info?.group?.length || 0})</Paragraph>
<div className={cl.teams}>
  {info?.group && info.group.length > 0 ? (
    info.group.map((team) => (
      <div className={cl.team} key={team}>
        <Paragraph>{team}</Paragraph>
        <CloseIcon onClick={() => onDeleteTeam(team)} />
      </div>
    ))
  ) : (
    <div className={cl.emptyState}>
      <img src={teamsEmptyImg} alt="No teams available" className={cl.emptyImage} />
      <Paragraph>There are no available teams yet</Paragraph>
    </div>
  )}
  {loading && <SpinnerOverlay width={35} />}
</div>


      {iRole &&
        iRole === "OWNER" &&
        iId !== info.id &&
        info.role !== "OWNER" && (
          <div className={cl.role}>
            <Paragraph>Role</Paragraph>
            <div className={cl.switch}>
              <Switch checked={admin} onChange={onChangeRole} />
              <RoleChips role="ADMIN" />
            </div>
          </div>
        )}
      {addToTeamsModal && availableTeams.length > 0 && (
        <Modal
          style={{ maxWidth: 440, width: "90%" }}
          modalIsOpen={addToTeamsModal}
          setIsOpen={setAddToTeamsModal}
        >
          <AddToTeamsModal
            teams={availableTeams}
            onConfirm={onAddToTeams}
            modalIsOpen={addToTeamsModal}
            setModalIsOpen={setAddToTeamsModal}
            loading={loadingAdd}
            error={errorAdd}
          />
        </Modal>
      )}
      {createModal && (
        <Modal
          style={{ maxWidth: "90%", width: 440 }}
          modalIsOpen={createModal}
          setIsOpen={setCreateModal}
        >
          <ContentModal
            loading={loading}
            setModalIsOpen={setCreateModal}
            header="Create team"
            onConfirm={onCreateTeam}
            closeOnConfirm={false}
            textButton="Create"
            disabled={createInput.length < 2}
          >
            <div style={{ width: "100%", marginBottom: 37 }}>
              <Input
                label="Team name"
                value={createInput}
                onChange={onChangeCreateInput}
                err={createInputError}
                helperText={createInputError}
              />
            </div>
          </ContentModal>
        </Modal>
      )}
    </div>
  );
}

export default AccountSettings;
