import React, { useState } from 'react'

import cl from './MainChart.module.css'
import {  Paragraph } from '../../UI/Typography/Typography'
import MainSelect from '../../UI/select/Select'
import NewChartLine from './mainChart/NewChartLine'
import SpinnerOverlay from '../../UI/spinner/SpinnerOverlay'



function CustomChart({ data, title = 'Users', options, onChangeSelectFilter, selectFilter, unit = '', small = false, loading = false }) {

	return (
		<div className={cl.table}>
			<div className={cl.header}>
				<Paragraph style={{lineHeight: '140%'}}>{title}</Paragraph>
				<MainSelect options={options} onChange={onChangeSelectFilter} width='165px' />
			</div>
			<div className={cl.chartLabel}>
				<Paragraph>{unit}</Paragraph>
			</div>
			<div className={cl.chart}>
				<NewChartLine data={data[selectFilter]} id={Math.random()} color={data.color} unit={unit} ticks={data.ticks} ticksMob={data.ticksMob} />
			</div>
			{loading && <SpinnerOverlay />}
		</div>
	)
}

export default CustomChart