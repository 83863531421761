import cl from './ChartTooltip.module.css'

function NewChartTooltip({ active, payload, unit = '', color }) {
	const icon = <>
		<i className={unit === '' || unit === 'min' ? 'icon-timer' : unit === 'sessions' ? 'icon-vr' : unit === 'videos' ? 'icon-video_library' : unit === 'users' ? 'icon-User' : 'icon-timer'}
			style={{ color: color }} />

	</>
	if (active && payload && payload.length) {
		return (
			<div className={cl.container}>
				<div className={cl.date}>
					<i className='icon-event_busy' />
					{payload[0].payload.tooltip}
				</div>
				<div className={cl.time}>
					{icon}
					{unit === 'min' ? payload[0].payload.time
						: payload[0].value + ' ' + unit}
				</div>
			</div>
		)
	}

	return null;
}

export default NewChartTooltip