import InfoInput from './personalInfo/InfoInput'

import cl from './PersonalInfo.module.css'
import ChangePasFromInfo from './personalInfo/ChangePasFromInfo'
import { useDispatch, useSelector } from 'react-redux'
import { editUserInfo } from '../../../redux/slices/UserInfoSlice'
import SpinnerBlock from '../../../UI/spinner/SpinnerBlock'
import DenialTextInformation from '../../../UI/accessDenialOverlay/DenialTextInformation'

function PersonalInfo({ userInfo, loading, access, getInfo }) {
	const dispatch = useDispatch()
	const iRole = useSelector((state) => state.userInfo.info.role)
	const verification = useSelector((state) => state.company?.info?.confirmed_email)
	const userInfoState = useSelector((state) => state.userInfo)
	const { info, loadingInfo, errorInfo } = userInfoState

	const { name, job, workerid, phone, location } = userInfo

	const inputs = [
		{ name: 'name', label: 'Full name', oldValue: name },
		{ name: 'job', label: 'Job title', oldValue: job },
		{ name: 'workerid', label: 'Employee ID', oldValue: workerid },
		{ name: 'phone', label: 'Contact number', oldValue: phone, type: 'tel' },
		{ name: 'location', label: 'Location', oldValue: location },
	]

	const editInfo = async (name, value) => {
		return await dispatch(editUserInfo({ [name]: value, id: userInfo.id }))
	}

	return (

		loading ? <SpinnerBlock height={250} /> :

			<div className='account__container'>
				{inputs.map((input) => (
					<div key={input.name} className={cl.row}>
						<InfoInput disabled={!access || !verification} callback={editInfo} errorInfo={errorInfo} oldValue={input.oldValue || ''} label={input.label} name={input.name}/>
					</div>
				))}
				{(userInfo.id === info.id && verification) ? <ChangePasFromInfo /> : null}
				{!verification && <DenialTextInformation />}
				
			</div>

	)
}

export default PersonalInfo