import { Paragraph } from '../Typography/Typography';
import cl from './EmptyNotice.module.css';

interface EmptyNoticeProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: string;  
  text: string;  
  subtext?: string;  
}

const EmptyNotice: React.FC<EmptyNoticeProps> = ({ icon, text, subtext, ...props }) => {
  return (
    <div className={cl.notice} {...props}>
      <i className={icon} />
      <Paragraph className={cl.dark}>{text}</Paragraph>
      {subtext && <Paragraph>{subtext}</Paragraph>}
    </div>
  );
};

export default EmptyNotice;
