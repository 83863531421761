import { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import NewChartTooltip from "./NewChartTooltip";
import moment from "moment";
import { useWindowWidth } from "../../../hooks/useWindowWidth";

function NewChartLine({ data, color = '', id, unit, loading = false, ticks, ticksMob }) {
	const winWidth = useWindowWidth()
	const CustomTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			return (
				<NewChartTooltip payload={payload} unit={unit} active={active} color={color || "#8884d8"} />
			);
		}

		return null;
	};

	const CustomizedAxisTick = (...args) => {
		const { x, y, stroke, payload } = args[0];
		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={16} textAnchor="end" fill="#6D7681" transform="rotate(-45)" style={{ fontSize: 14 }}>
					{payload.value}
				</text>
			</g>
		);
	};

	const tickFormatterY = (value) => {
		let newValue

		// Используем функцию выше, чтобы проверить, нужно ли отображать этот тик
		if (unit !== 'min') {
			newValue = value
			return newValue
		} else if (value > 999999) {
			newValue = (value / 1000).toFixed(0) + 'kk'
		} if (value > 999) {
			newValue = (value / 1000).toFixed(0) + 'k'
		} else {
			newValue = value
		}
		return newValue

	};

	const getMaxY = (data) => {
		let max = Math.max(...data.map((item) => item.uv))
		if(max === 0) {
			return 1
		} else {
			return max
		}
	};

	return (
		<ResponsiveContainer width="100%" height="100%" style={{ marginLeft: -12 }}>
			<AreaChart
				width={500}
				height={300}
				data={data}
				margin={{
					top: 0,
					right: 10,
					left: 0,
					bottom: 45,
				}}
			>
				<defs>
					<linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor={color || "#8884d8"} stopOpacity={0.8} />
						<stop offset="95%" stopColor={color || "#8884d8"} stopOpacity={0} />
					</linearGradient>
				</defs>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis
					dataKey="name"
					tick={<CustomizedAxisTick />}
					interval={data?.length > 180 ? 0 : 'auto'}
					ticks={(winWidth > 991 && data?.length > 180) ? ticks : (winWidth < 991 && data?.length > 180) ? ticksMob : []}
					name="name"
					includeHidden
				/>
				<YAxis textAnchor="end" allowDecimals={false} tickSize={10} tick={{ stroke: '#6D7681', strokeOpacity: '0.2', fontSize: 14, textAlign: 'left' }} tickLine={false} tickFormatter={tickFormatterY} domain={[0, data?.length ? getMaxY(data) : 'auto' ]}  />
				<Tooltip content={<CustomTooltip />} />
				<Area type="monotone" dataKey="uv" stroke={color || "#8884d8"} fillOpacity={1} fill={`url(#${id})`} />
			</AreaChart>
		</ResponsiveContainer>
	)
}

export default NewChartLine