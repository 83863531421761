import React, { useEffect, useState } from 'react'
import Button from '../../UI/Button/Button'
import { formatDistanceToNowStrict, parseISO, differenceInMinutes, differenceInHours, addHours, addMinutes, isAfter, format } from 'date-fns';

import cl from './VerificationBunner.module.css'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { newRegistrationConfirmationResendMail } from '../../redux/slices/LoginSlice'
import { cleanNotice } from '../../redux/slices/NoticeSlice'
import { Paragraph } from '../../UI/Typography/Typography'

function VerificationBunner() {
	const [loading, setLoading] = useState(false);
	const [waitingTime, setWaitingTime] = useState(null);
	const comId = useSelector((state) => state.company.info?.id || null);
	const email = useSelector((state) => state.userInfo.info?.email || undefined);
	const noticeSent = useSelector(state => state.notice.sentFromNotice)
	const dispatch = useDispatch();

	const [notice, setNotice] = useState(null)

	useEffect(() => {
		if (!comId) return
		let not = localStorage.getItem(`${comId}-activation`)
		if (not) {
			setNotice(JSON.parse(not))
		}

	}, [comId, noticeSent])


	useEffect(() => {
		if (!notice) return

		if (notice.timerCount === 0) {
			setWaitingTime(null);
			return;
		}

		//if (notice.timerCount >= 5) {
		//	setWaitingTime(null);
		//	return;
		//}

		
		const now = new Date();
		const timerDate = new Date(notice.timer);

		if (isAfter(now, timerDate) || now.getTime() === timerDate.getTime()) {
			setWaitingTime(null);
		} else {
			// Таймер ещё не истёк, письмо переотправить нельзя
			// Сообщить пользователю, сколько времени осталось до следующей возможной переотправки


			const diffMinutes = differenceInMinutes(timerDate, now);

			let newWaitingTime;

			if (diffMinutes === 0) {
				newWaitingTime = '1 min';
			} else if (diffMinutes < 60) {
				newWaitingTime = `${diffMinutes} min`;
			} else {
				const diffHours = Math.ceil(diffMinutes / 60);
				newWaitingTime = `${diffHours} h`;
			}

			// Обновляем состояние только если оно изменилось
			if (newWaitingTime !== waitingTime) {
				setWaitingTime(newWaitingTime);
			}
		};


		// Вызываем один раз при монтировании компонента и затем каждую минуту

		//const intervalId = setInterval(calculateWaitingTime, 60000);

		//return () => clearInterval(intervalId);
	}, [notice]);

	const onResend = async () => {
		setLoading(true);

		const res = await dispatch(newRegistrationConfirmationResendMail(email));

		if (res.payload?.status === 'success') {
			dispatch(cleanNotice())

			let newTimer = new Date(); // Начинаем с текущего времени

			// Проверяем количество отправленных сообщений
			if (notice.timerCount < 5) {
				switch (notice.timerCount) {
					case 0:
						newTimer = addMinutes(newTimer, 1); // Добавляем 1 минуту
						break;
					case 1:
						newTimer = addMinutes(newTimer, 30); // Добавляем 30 минут
						break;
					case 2:
						newTimer = addHours(newTimer, 1); // Добавляем 1 час
						break;
					case 3:
						newTimer = addHours(newTimer, 2); // Добавляем 2 часа
						break;
					case 4:
						newTimer = addHours(newTimer, 12); // Добавляем 12 часов
						break;
				}
			} else {
				// Если это шестая отправка, сбрасываем таймер на текущее время
				newTimer = addMinutes(newTimer, 1);
			}

			let newTimerCount = notice.timerCount > 4 ? 1 : notice.timerCount + 1; // Обновляем количество отправленных сообщений
			const updatedNotice = {
				...notice,
				timer: newTimer,
				timerCount: newTimerCount,
				status: notice.status === 'hide' ? 'hide' : 'canceled'
			};
			localStorage.setItem(`${comId}-activation`, JSON.stringify(updatedNotice));
			setNotice(updatedNotice);
		}
		setLoading(false);
	};

	return (
		<div className={cl.bunner}>
			<Paragraph className={cl.bunner__text}>
				To unlock all features, <br /> <span>please</span> verify your email.
			</Paragraph>
			<div className={cl.bunner__btn}>
				<Button onClick={onResend} disabled={Boolean(waitingTime) || loading || !notice} loading={loading || !notice}>
					{waitingTime ? `Resend in ${waitingTime}` : 'Resend email'}
				</Button>
			</div>
		</div>
	);
}

export default VerificationBunner;