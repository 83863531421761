import React, { useEffect, useState } from 'react'
import Button from '../../../../UI/Button/Button'
import GridCardVideo from '../../../../UI/gridContent/GridCardVideo'
import GridCardVR from '../../../../UI/gridContent/GridCardVR'
import GridItem from '../../../../UI/gridTable/GridItem'
import GridTable from '../../../../UI/gridTable/GridTable'
import { TABLET_WIDTH, useWindowWidth } from './../../../../hooks/useWindowWidth';

function ContentGrid({ content, loading, onRemoveAccess = null, vr = false }) {
	const winWidth = useWindowWidth()
	const [visibleVRContent, setVisibleVRContent] = useState([])
	const [visibleVidContent, setVisibleVidContent] = useState([])
	const [showVRBtn, setShowVRBtn] = useState(false)
	const [showVidBtn, setShowVidBtn] = useState(false)

	useEffect(() => {
		if (vr) return
		if (showVidBtn) {
			setVisibleVidContent(content)
		} else {
			if (winWidth < TABLET_WIDTH) {
				setVisibleVidContent(content.slice(0, 3))
			} else {
				setVisibleVidContent(content.slice(0, 5))
			}
		}

	}, [showVidBtn, content, winWidth])


	useEffect(() => {
		;
		if (showVRBtn) {
			setVisibleVRContent(content)
		} else {
			if (winWidth < TABLET_WIDTH) {
				setVisibleVRContent(content.slice(0, 3))
			} else {
				setVisibleVRContent(content.slice(0, 6))
			}
		}

	}, [showVRBtn, content, winWidth])

	const vrButtns = <>
		{winWidth > TABLET_WIDTH
			? content?.length > 6
			&& <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
				{
					showVRBtn
						? <Button textbtn icon='up' right onClick={() => setShowVRBtn(false)}>Show less</Button>
						: <Button textbtn icon='down' right onClick={() => setShowVRBtn(true)}>Show more</Button>
				}
			</div>
			: content?.length > 3
			&& <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
				{
					showVRBtn
						? <Button textbtn icon='up' right onClick={() => setShowVRBtn(false)}>Show less</Button>
						: <Button textbtn icon='down' right onClick={() => setShowVRBtn(true)}>Show more</Button>
				}
			</div>}
	</>

	const videoButtns = <>
		{winWidth > TABLET_WIDTH
			?
			content?.length > 5
			&& <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
				{
					showVidBtn
						? <Button textbtn icon='up' right onClick={() => setShowVidBtn(false)}>Show less</Button>
						: <Button textbtn icon='down' right onClick={() => setShowVidBtn(true)}>Show more</Button>
				}
			</div>
			:
			content?.length > 3
			&& <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
				{
					showVidBtn
						? <Button textbtn icon='up' right onClick={() => setShowVidBtn(false)}>Show less</Button>
						: <Button textbtn icon='down' right onClick={() => setShowVidBtn(true)}>Show more</Button>
				}
			</div>
		}
	</>





const vrGrid = (
	<>
		<GridTable vr={vr} loading={loading} >
			{visibleVRContent?.length > 0 && visibleVRContent.map((video) => (
				<GridItem key={video.id} > {/* ключ */}
					<GridCardVR {...video} removeAccess={onRemoveAccess} />
				</GridItem>
			))}
		</GridTable>
		{vrButtns}
	</>
)

const videoGrid = (
	<>
		<GridTable vr={vr} loading={loading}>
			{visibleVidContent?.length > 0 && visibleVidContent.map((video) => (
				<GridItem key={video.id}> {/* ключ */}
					<GridCardVideo {...video} removeAccess={onRemoveAccess} />
				</GridItem>
			))}
		</GridTable>
		{videoButtns}
	</>
)

	return (

		vr ? vrGrid : videoGrid
	)
}

export default ContentGrid